<template>
  <el-dialog title="关联报销" width="780px" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfData"
        :rules="rules"
      />
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">提交审核</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    billId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {
        bill_id: this.billId,
        bill_type: '报销单',
        remark_explain: '',
        explain_price: '',
        remark: '',
        cash_photo_list: []
      },
      formConfig: {
        inline: false,
        width: '100%'
      },
      formColumn: [
        {
          type: 'select',
          title: '报销事由',
          field: 'remark_explain',
          required: true,
          options: [
            {
              key: '销售运费',
              val: '销售运费'
            },
            {
              key: '差旅',
              val: '差旅'
            },
            {
              key: '住宿',
              val: '住宿'
            },
            {
              key: '交通',
              val: '交通'
            },
            {
              key: '招待',
              val: '招待'
            },
            {
              key: '其他',
              val: '其他'
            }
          ]
        },
        {
          type: 'text',
          title: '报销金额(元)',
          field: 'explain_price',
          required: true
        },
        {
          type: 'text',
          title: '备注',
          field: 'remark'
        },
        {
          type: 'upload',
          title: '付款凭证',
          field: 'cash_photo_list',
          isDelete: true
        }
      ],
      rules: {
        remark_explain: [{ required: true, message: '请选择报销事由' }],
        explain_price: [{ required: true, message: '请输入报销金额' }]
      }
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
  },
  methods: {
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.$refs.refForm.validate((valid) => {
        if (valid) {
          this.$api({
            method: 'post',
            url: '/admin/gcvip/reportBill/saveOrUpdateByCash',
            data: this.selfData
          })
            .then((res) => {
              if (res.data.state === 'ok') {
                this.popupCancel()
                this.$emit('success')
                this.$message.success('保存成功')
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((error) => {
              this.$message.error(error)
            })
        }
      })
    }
  }
}
</script>

<style></style>
