<template>
  <div class="page-main">
    <div class="page-l" v-if="billId">
      <b-detail-nav :id="billId" />
    </div>
    <div class="page-r">
      <e-form
        class="p-t-10 b-t-1"
        ref="refForm1"
        :config="formConfig"
        :column="formColumn1"
        :data.sync="detailData.formData"
        disabled
      />
      <div class="page-title">收款记录</div>
      <vxe-grid
        ref="refTable"
        size="mini"
        border
        :min-height="42"
        :columns="skColumn"
        :data="detailData.collection_list"
        :show-footer="detailData.collection_list && !!detailData.collection_list.length"
        :footer-method="skFooterMethod"
      >
        <!-- slot_default -->
        <template #default_create_time="{ row }">
          {{ row.create_time.slice(0, 10) }}
        </template>
        <template #default_cash_status="{ row }">
          <span v-if="row.cash_status === '待审核'" :style="{ color: '#999' }">
            {{ row.cash_status }}
          </span>
          <span v-if="row.cash_status === '已提交'" :style="{ color: '#66b1ff' }">
            {{ row.cash_status }}
          </span>
          <span v-if="row.cash_status === '已审核'" :style="{ color: '#67c23a' }">
            {{ row.cash_status }}
          </span>
        </template>
        <template #default_cash_photo_list="{ row }">
          <e-upload-image
            v-if="row.cash_photo_list"
            size="30px"
            disabled
            :list.sync="row.cash_photo_list"
          />
        </template>
      </vxe-grid>

      <div class="page-title m-t-10">报销记录</div>
      <vxe-grid
        ref="refTable"
        size="mini"
        border
        :min-height="42"
        :columns="bxColumn"
        :data="detailData.reimburse_list"
        :show-footer="detailData.reimburse_list && !!detailData.reimburse_list.length"
        :footer-method="bxFooterMethod"
      >
        <!-- slot_default -->
        <template #default_create_time="{ row }">
          {{ row.create_time.slice(0, 10) }}
        </template>
        <template #default_cash_status="{ row }">
          <span v-if="row.cash_status === '待审核'" :style="{ color: '#999' }">
            {{ row.cash_status }}
          </span>
          <span v-if="row.cash_status === '已提交'" :style="{ color: '#66b1ff' }">
            {{ row.cash_status }}
          </span>
          <span v-if="row.cash_status === '已审核'" :style="{ color: '#67c23a' }">
            {{ row.cash_status }}
          </span>
        </template>
        <template #default_cash_photo_list="{ row }">
          <e-upload-image
            v-if="row.cash_photo_list"
            size="30px"
            disabled
            :list.sync="row.cash_photo_list"
          />
        </template>
      </vxe-grid>

      <div class="flex flex-x-end m-t-10">
        <el-button type="primary" size="mini" @click="detailSk">开收款单</el-button>
        <el-button type="primary" size="mini" @click="detailBx">关联报销</el-button>
        <!-- <el-button type="default" size="mini" @click="detailSj">生成收据</el-button> -->
      </div>
    </div>

    <!-- 开收款单 -->
    <PopupSk
      v-if="isShowPopupSk"
      :show.sync="isShowPopupSk"
      :billId="billId"
      @success="getDetailData"
    />

    <!-- 开收款单 -->
    <PopupBx
      v-if="isShowPopupBx"
      :show.sync="isShowPopupBx"
      :billId="billId"
      @success="getDetailData"
    />
  </div>
</template>

<script>
import PopupSk from '../../../common/popup-sk'
import PopupBx from '../../../common/popup-bx'
export default {
  components: {
    PopupSk,
    PopupBx
  },
  data() {
    return {
      formConfig: {
        width: '200px'
      },
      formColumn1: [
        {
          type: 'text',
          title: '销售日期',
          field: 'bill_date'
        },
        {
          type: 'text',
          title: '销售单号',
          field: 'bill_code'
        },
        {
          type: 'text',
          title: '客户',
          field: 'company_name'
        },
        {
          type: 'text',
          title: '业务员',
          field: 'saler_name'
        },
        {
          type: 'text',
          title: '销售方式',
          field: 'saler_type'
        },
        {
          type: 'text',
          title: '合同应收',
          field: 'sales_amount_total'
        },
        {
          type: 'text',
          field: 'amount_received',
          title: '合同已收'
        },
        {
          type: 'text',
          title: '合同未收',
          field: 'un_amount_received'
        }
      ],
      skColumn: [
        {
          title: '序号',
          type: 'seq'
        },
        {
          title: '收款日期',
          field: 'create_time',
          slots: { default: 'default_create_time' }
        },
        {
          title: '收款单号',
          field: 'id'
        },
        {
          title: '收款账户',
          field: 'bank_name'
        },
        {
          title: '收款金额(元)',
          field: 'amount_received'
        },
        {
          title: '实收金额(元)',
          field: 'funds_received'
        },
        {
          title: '收款类型',
          field: 'collection_type'
        },
        {
          title: '客户付款凭证',
          field: 'cash_photo_list',
          slots: { default: 'default_cash_photo_list' }
        },
        {
          title: '收款备注',
          field: 'remark'
        },
        {
          title: '收款状态',
          field: 'cash_status',
          slots: { default: 'default_cash_status' }
        }
      ],
      bxColumn: [
        {
          title: '序号',
          type: 'seq'
        },
        {
          title: '报销日期',
          field: 'create_time',
          slots: { default: 'default_create_time' }
        },
        {
          title: '报销单号',
          field: 'id'
        },
        {
          title: '报销事由',
          field: 'remark_explain'
        },
        {
          title: '报销金额(元)',
          field: 'explain_price'
        },
        {
          title: '付款凭证',
          field: 'cash_photo_list',
          slots: { default: 'default_cash_photo_list' }
        },
        {
          title: '报销备注',
          field: 'remark'
        },
        {
          title: '单据状态',
          field: 'cash_status',
          slots: { default: 'default_cash_status' }
        }
      ],
      detailData: {},
      isShowPopupSk: false,
      isShowPopupBx: false
    }
  },
  computed: {
    billId() {
      return this.$route.query.id
    }
  },
  mounted() {
    if (this.billId) {
      this.getDetailData()
    }
  },
  methods: {
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/reportBill/billCashById',
        params: {
          id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.detailData = this.$util.deepCopy(res.data)
          this.detailData.formData.bill_date = this.detailData.formData.bill_date.slice(0, 10) // 截取前10
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 表格底部
    skFooterMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (['amount_received', 'funds_received'].includes(column.field)) {
            return this.sumNum(data, column.field)
          }
        })
      ]
    },
    // 表格底部
    bxFooterMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (['funds_received'].includes(column.field)) {
            return this.sumNum(data, column.field)
          }
        })
      ]
    },
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    },
    // 开收款单
    detailSk() {
      this.isShowPopupSk = true
    },
    // 关联报销
    detailBx() {
      this.isShowPopupBx = true
    },
    // 生成收据
    detailSj() {}
  }
}
</script>

<style lang="less" scoped>
.page-main {
  position: relative;
  display: flex;
  height: 100%;
  .page-l {
    margin: 0 10px 10px 0;
  }
  .page-r {
    flex: 1;
  }
  .page-title {
    line-height: 40px;
    font-weight: bold;
    font-size: 12px;
    border-bottom: 1px solid @colorGrayD;
    margin-bottom: 10px;
  }
}
</style>
